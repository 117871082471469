<template>
  <div class="goals__container">
    <div class="goals__header">
      <form class="goals__filters" @submit.prevent>
        <button class="goals__filter ed-btn__sm ed-btn__blim" @click="toggleFilter('all')">@All</button>
        <button class="goals__filter ed-btn__sm ed-btn__blim" @click="toggleFilter('completed')">@Completed</button>
        <button class="goals__filter ed-btn__sm ed-btn__blim" @click="toggleFilter('incomplete')">@Incomplete</button>
      </form>
      <div class="goals__toolbar">
        <div class="goals__tool">
          <i class="box-icon bx bx-plus" title="Add new Goal" @click="toggleAddModal()"></i>
        </div>
        <div class="goals__tool">
          <i class="box-icon bx bx-refresh"></i>
        </div>
      </div>
    </div>
    <div class="goals__main">
      <Goal v-for="goal in goalsData" :key="goal.id" :goal="goal" />
    </div>
    <!-- add Model -->
    <div class="goals__modal-background" v-if="isAddModel">
      <div class="goals__modal">
        <form action="" class="goals__modal-form" @submit.prevent="addGoal()">
          <!-- <input type="text" class="text" v-model="newGoal" placeholder="Enter the Goal...."/> -->
          <h5 class="goals__modal-label">Add a new Goal</h5>
          <div class="goals__modal-input">
            <edInput :onChange="setGoalName" placeholder="Enter the Goal..." />
          </div>
          <button type="button" class="ed-btn__blim" @click="addGoal()">Create</button>
        </form>
        <!-- Close button -->
        <div class="goals__modal-close" @click="toggleAddModal()">
          <i class="box-icon bx bx-x"></i>
        </div>
      </div>
    </div>

    <!-- Add Attachment -->
    <!-- <div class="goals__modal-background">
      <div class="goals__modal">
        <form action="#" class="goals__modal-form">
          <input type="text" class="text" placeholder="Enter the Goal...."/>
          <button type="button" class="ed-btn__blim" @click="addGoal()">Create</button>
        </form>
      </div>
    </div> -->
  </div>
</template>

<script>
import Goal from '@/common/widgets/GoalsWidget/Goal.vue';
import edInput from '@/common/components/ed-forms/ed-input.vue';

export default {
  name: 'GoalsWidget',
  components: {
    Goal,
    edInput,
  },
  created() {
    setTimeout(() => {
      this.$store.dispatch('goals/fetchGoals');
    }, 1000);
  },
  data() {
    return {
      filter: 'all',
      newGoal: '',
    };
  },
  computed: {
    goalsData() {
      // Fetch goals from the store and return the data
      // Replace 'fetchGoalsFromStore()' with your actual logic to fetch goals from the store
      if (this.filter === 'completed') {
        return this.$store.getters['goals/fetchCompletedGoals'];
      }
      if (this.filter === 'incomplete') {
        return this.$store.getters['goals/fetchIncompleteGoals'];
      }

      return this.$store.state.goals.goals;
    },
    isAddModel() {
      return this.$store.state.goals.addModal;
    },
    isAttachmentModal() {
      return this.$store.state.goals.attachmentModal;
    },
  },
  methods: {
    toggleAddModal() {
      this.$store.dispatch('goals/toggleAddModal');
    },
    refreshGoals() {
      // Method to refresh the goals
      // Replace 'refreshGoalsFromStore()' with your actual logic to refresh goals
      this.refreshGoalsFromStore();
    },
    addGoal() {
      // Method to add a new goal
      // Replace 'addGoalToStore()' with your actual logic to add a goal to the store
      this.$store.dispatch('goals/addGoal', { goal: this.newGoal });
      this.toggleAddModal();
      this.newGoal = '';
    },
    filterGoals() {
      // Method to filter goals based on certain criteria
      // Replace 'filterGoalsInStore()' with your actual logic to filter goals in the store
      this.filterGoalsInStore();
    },
    fetchNotes() {
      // fetch notes Methods for attachement to goals
    },
    setGoalName(val) {
      this.newGoal = val;
    },
    toggleFilter(newFilter) {
      this.filter = newFilter;
    },
  },
};
</script>

<style lang="scss">
// goals__container
.goals__container {
  // goals__header
  .goals__header {
    // goals__toolbar
    .goals__toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // goals__tool
      .goals__tool {
        // styles for bx-plus icon
        i.bx.bx-plus {
          // add your styles here
        }
      }
      // goals__tool
      .goals__tool {
        // styles for bx-refresh icon
        i.bx.bx-refresh {
          // add your styles here
        }
      }
    }
  }

  // goals__main
  .goals__main {
    // styles for the Goal component
    // use the :host selector if it's a Vue component
    // add your styles here
  }

  .goals__filter {
    margin-right: .5rem;

    &:last-child {
      margin-right: unset;
    }
  }

  // goals__modal-background
  .goals__modal-background {
    // styles for the goals modal background
    // add your styles here
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    position: fixed;
    background: rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    // goals__modal
    .goals__modal {
      // styles for the goals modal
      // add your styles here
      height: 30vh;
      width: 50%;
      min-width: 240px;
      background: #fff;
      border-radius: .3rem;
      // box-shadow: $shadow-1;
      position: relative;

      // goals__modal-form
      .goals__modal-form {
        // styles for the goals modal form
        // add your styles here
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        // input[type="text"]
        .goals__modal-input {
          // styles for the text input
          // add your styles here
          width: 100%;
          display: flex;
          justify-content: center;

          .ed-input {
            width: 576px;
          }
        }

        // button.ed-btn__blim
        button.ed-btn__blim {
          // styles for the "Create" button
          // add your styles here
        }
      }

      .goals__modal-input {
        margin: 1rem 0;
      }

      .goals__modal-close {
        position: absolute;
        top: .5rem;
        right: .5rem;
      }
    }
  }
}

</style>
