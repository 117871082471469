<template>
  <div class="goal" :class="isAttachmentRequested ? 'goal__attachment' : ''">
    <div class="goal__header"></div>
    <div class="goal__main">
      <form class="goal__form" @submit.prevent="toggleEdit()">
        <label :for="goal.id" class="goal__text-container" v-if="!isEdit">
          <input
            type="checkbox"
            class="checkbox"
            :checked="goal.status"
            @change="markAsComplete"
            :id="goal.id"
          />
          <span class="goal__text">{{ goal.goal }}</span>
        </label>
        <edInput
          v-else
          :onChange="setGoalName"
          placeholder="Enter the Goal to Update"
          :value="goal.goal"
        />
      </form>
    </div>
    <hr />
    <div class="goal__footer">
      <div class="goal__preferences">
        <div v-if="goal.attachment.length > 0" class="goal__notes-toggle">
          <i class="bx bx-chevron-down box-icon" @click="toggleShowNotes()"></i>
        </div>
        <div
          class="goal__toolbar"
          :style="{ marginLeft: goal.attachment.length > 0 ? 'unset' : 'auto' }"
        >
          <div class="goal__tool">
            <i
              class="box-icon bx bx-edit-alt"
              title="Attach a Note"
              @click="toggleEdit()"
            ></i>
          </div>
          <div class="goal__tool">
            <i
              class="box-icon bx bx-paperclip"
              :class="checkAttachmentToolStatus() ? 'box-icon--disabled' : ''"
              title="Attach a Note"
              @click="createAttachmentRequest()"
            ></i>
          </div>
          <div class="goal__tool">
            <i
              class="box-icon bx bx-trash"
              title="Delete Goal"
              @click="deleteGoal()"
            ></i>
          </div>
        </div>
      </div>
      <div class="goal__notes-container" v-if="isShowNotes">
        <h5>Attached Notes</h5>
        <hr />
        <div class="goal__notes">
          <div
            v-for="noteId in goal.attachment"
            :key="noteId"
            v-html="noteDetail(fetchNoteById(noteId))"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import edInput from '@/common/components/ed-forms/ed-input.vue';

export default {
  name: 'Goal',
  props: {
    goal: {
      type: Object,
      default: () => ({
        timestamp: {
          machineReadable: '2023-05-26T09:01:18.047Z',
          humanReadable: 'May 26th 2023, 2:31:18 pm',
        },
        goal: 'Theory of One piece',
        status: false,
        actor: 'nischay.s.ed',
        attachment: [],
        tags: [],
        verb: 'CREATED_GOAL',
        _id: '6470755ede98696902332b10',
        event: '',
        __v: 0,
        uid: '6470755ede98696902332b10',
        id: '6470755ede98696902332b10',
      }),
    },
  },
  data() {
    return {
      isEdit: false,
      isShowNotes: false,
    };
  },
  components: {
    edInput,
  },
  computed: {
    isAttachmentRequested() {
      if (
        this.$store.state.goals.selectedGoal
        && this.$store.state.goals.selectedGoal.id === this.goal.id
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    editGoal() {
      // Method for editing the goal
    },
    createAttachmentRequest() {
      // Method for adding attachments to the goal
      if (!this.goal.status) {
        this.$store.dispatch('goals/createAttachmentRequest', {
          goal: this.goal,
        });
      }
    },
    markAsComplete() {
      // Method for marking the goal as complete
      this.$store.dispatch('goals/markAsComplete', { goal: this.goal });
    },
    deleteGoal() {
      // Method for deleting the goal
      this.$store.dispatch('goals/deleteGoal', { goal: this.goal });
    },
    toggleAttachmentModal() {
      this.$store.dispatch('goals/toggleAttachmentModal');
    },
    setGoalName(val) {
      this.goal.goal = val;
    },
    noteDetail(note) {
      const component = `
        <div class="goal__note">
          <div class="goal__note-content">
            ${note.value.note.slice(0, 25)}
          </div>
          <div class="goal__note-author">
            ${note.actor.username}
          </div>
        </div>
        `;
      return component;
    },
    fetchNoteById(id) {
      const Note = this.$store.getters.fetchNoteById(id);
      console.log(Note.value.note);
      // const words = Note.value.note.trim().split(' ');
      // const truncatedWords = words.slice(0, 25);
      // const truncatedString = truncatedWords.join(' ');
      // return truncatedString;
      return Note;
    },
    toggleEdit() {
      if (this.isEdit) {
        this.$store.dispatch('goals/updateAttachment', {
          goal: { id: this.goal.id },
          query: {
            goal: this.goal.goal,
          },
        });
      }
      this.isEdit = !this.isEdit;
    },
    toggleShowNotes() {
      this.isShowNotes = !this.isShowNotes;
    },
    checkAttachmentToolStatus() {
      if (this.goal.status || this.goal.attachment.length > 1) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.goal {
  position: relative;
  min-height: 5rem;
  padding: 2rem 1rem;
  padding-bottom: 0.5rem;
  background: var(--bs-gray-100);
  margin-bottom: 1rem;
  &__attachment {
    background: rgba($edvantaYellow, 0.1);
  }
  &__header {
    // CSS properties for the header
  }

  &__main {
    // CSS properties for the main section
    .goal__form {
      // CSS properties for the form
      .goal__text-container {
        // CSS properties for the text container label
        display: flex;
        font-size: 1.1rem;
        pointer-events: none;
        user-select: none;
        .checkbox {
          // CSS properties for the checkbox input
          margin-right: 0.5rem;
        }

        .goal__text {
          // CSS properties for the text span
        }
      }
    }
  }

  &__footer {
    // CSS properties for the footer
  }
  &__preferences {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // position: absolute;
  }
  &__tool {
  }

  &__notes-container {
    padding-top: 1rem;
    margin-top: .5rem;
  }
  &__note {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__note-author {
    color: #9b9595;
    font-size: 0.85rem;
    font-style: italic;
    font-weight: 500;
    cursor: default;
  }
}
</style>
